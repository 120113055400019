import React, { useState, useEffect } from "react";
import { Plus, Upload, Pencil, Trash2, X, Check, User, ChevronRight } from "lucide-react";
import "./CategoryManager.scss";
import SubcategoryManager from './SubcategoryManager';

const CategoryManager = () => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const [newCategory, setNewCategory] = useState({
    name: "",
    description: "",
    icon: null,
  });
  const [editingId, setEditingId] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validate file type
      if (!file.type.match("image.*")) {
        setError("Please select an image file (JPG, PNG, or GIF)");
        return;
      }

      // Validate file size (5MB)
      if (file.size > 5 * 1024 * 1024) {
        setError("Image size must be less than 5MB");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        // Create an image element to check dimensions
        const img = new Image();
        img.onload = () => {
          if (img.width > 2000 || img.height > 2000) {
            setError("Image dimensions must be less than 2000x2000 pixels");
            setPreviewImage(null);
            return;
          }
          setPreviewImage(reader.result);
          setNewCategory((prev) => ({ ...prev, icon: file }));
          setError(null);
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        "https://playninjarift.com/server/admin/get_categories.php"
      );
      if (!response.ok) throw new Error("Failed to fetch categories");

      const result = await response.json();
      console.log("Categories response:", result);

      if (result.status === "error") {
        throw new Error(result.message);
      }

      const categories = result.data || [];
      setCategories(categories);
      console.log("Fetched Categories:", categories);

      setError(null);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setError(error.message || "Failed to load categories");
      setCategories([]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!newCategory.name.trim()) {
      setError("Category name is required");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name", newCategory.name.trim());
      formData.append("description", newCategory.description.trim());
      if (newCategory.icon) {
        formData.append("icon", newCategory.icon);
      }

      const response = await fetch(
        "https://playninjarift.com/server/admin/add_category.php",
        {
          method: "POST",
          body: formData,
        }
      );

      const result = await response.json();
      console.log("Server response:", result);

      if (result.status === "error") {
        throw new Error(result.message);
      }

      if (result.icon_url) {
        setUploadedImageUrl(result.icon_url);
      }

      // Reset form and refresh categories
      setNewCategory({ name: "", description: "", icon: null });
      setPreviewImage(null);
      setError(null);
      await fetchCategories();
    } catch (error) {
      console.error("Error creating category:", error);
      setError(error.message || "Failed to create category");
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    return new Date(dateString).toLocaleString();
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  // If a category is selected, show the SubcategoryManager
  if (selectedCategory) {
    return (
      <SubcategoryManager
        categoryId={selectedCategory.category_id}
        categoryName={selectedCategory.name}
        onBack={() => setSelectedCategory(null)}
      />
    );
  }

  return (
    <div className="category-manager">
      <div className="category-header">
        <h2>Category Manager</h2>
      </div>

      {error && (
        <div className="error mb-4 p-4 bg-red-100 text-red-700 rounded-md">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="add-category-form">
        <div className="form-group">
          <input
            type="text"
            placeholder="Category Name"
            value={newCategory.name}
            onChange={(e) => {
              setError(null);
              setNewCategory((prev) => ({ ...prev, name: e.target.value }));
            }}
            required
            className="focus:ring-2 focus:ring-indigo-500"
          />
          <input
            type="text"
            placeholder="Description"
            value={newCategory.description}
            onChange={(e) =>
              setNewCategory((prev) => ({
                ...prev,
                description: e.target.value,
              }))
            }
            className="focus:ring-2 focus:ring-indigo-500"
          />
          <div className="image-upload">
            <label className="upload-button">
              <input
                type="file"
                accept="image/jpeg,image/png,image/gif"
                onChange={handleFileChange}
                className="hidden"
              />
              <Upload size={20} />
              {newCategory.icon ? "Change Icon" : "Upload Icon"}
            </label>
            {previewImage && (
              <div className="image-preview">
                <img
                  src={previewImage}
                  alt="Preview"
                  className="max-w-24 max-h-24 object-contain"
                />
                {uploadedImageUrl && (
                  <div className="mt-2">
                    <img
                      src={uploadedImageUrl}
                      alt="Uploaded"
                      className="max-w-24 max-h-24 object-contain"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "https://via.placeholder.com/60";
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <button
            type="submit"
            className="add-button"
            disabled={!newCategory.name.trim()}
          >
            <Plus size={20} />
            Add Category
          </button>
        </div>
      </form>

      <div className="categories-list">
        {Array.isArray(categories) && categories.length > 0 ? (
          categories.map((category) => (
            <div key={category.category_id} className="category-item">
              {editingId === category.category_id ? (
                <EditCategoryForm
                  category={category}
                  onSave={async (updatedData) => {
                    try {
                      const formData = new FormData();
                      formData.append("category_id", category.category_id);
                      formData.append("name", updatedData.name.trim());
                      formData.append(
                        "description",
                        updatedData.description.trim()
                      );
                      if (updatedData.icon instanceof File) {
                        formData.append("icon", updatedData.icon);
                      }

                      const response = await fetch(
                        "https://playninjarift.com/server/admin/update_category.php",
                        {
                          method: "POST",
                          body: formData,
                        }
                      );

                      const result = await response.json();
                      if (result.status === "error") {
                        throw new Error(result.message);
                      }

                      await fetchCategories();
                      setEditingId(null);
                      setError(null);
                    } catch (error) {
                      console.error("Error updating category:", error);
                      setError(error.message || "Failed to update category");
                    }
                  }}
                  onCancel={() => setEditingId(null)}
                />
              ) : (
                <>
                  <div 
                    className="category-info cursor-pointer hover:bg-gray-50 rounded-lg transition-colors"
                    onClick={() => setSelectedCategory(category)}
                  >
                    {category.icon && (
                      <div className="category-icon">
                        <img
                          src={category.icon_url || "https://via.placeholder.com/60"}
                          alt={category.name}
                          className="max-w-24 max-h-24 object-contain"
                          onError={(e) => {
                            console.error(
                              "Failed to load image:",
                              category.icon_url
                            );
                            e.target.onerror = null;
                            e.target.src = "https://via.placeholder.com/60";
                          }}
                        />
                      </div>
                    )}
                    <div className="category-text">
                      <div className="flex items-center gap-2">
                        <h3>{category.name}</h3>
                        <ChevronRight size={16} className="text-gray-400" />
                      </div>
                      <p>{category.description}</p>
                      <div className="metadata text-sm text-gray-500">
                        {category.modified_by && (
                          <div className="flex items-center gap-2">
                            <User size={14} />
                            <span>Modified by: {category.modified_by}</span>
                          </div>
                        )}
                        <div className="text-xs">
                          Created: {formatDate(category.created_at)}
                          <br />
                          Updated: {formatDate(category.updated_at)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="actions">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingId(category.category_id);
                      }}
                      className="p-2 hover:bg-gray-100 rounded-full"
                    >
                      <Pencil size={20} />
                    </button>
                    <button
                      className="delete p-2 hover:bg-red-100 rounded-full"
                      onClick={async (e) => {
                        e.stopPropagation();
                        if (
                          window.confirm(
                            "Are you sure you want to delete this category?"
                          )
                        ) {
                          try {
                            const formData = new FormData();
                            formData.append(
                              "category_id",
                              category.category_id
                            );

                            const response = await fetch(
                              "https://playninjarift.com/server/admin/delete_category.php",
                              {
                                method: "POST",
                                body: formData,
                              }
                            );

                            const result = await response.json();
                            if (result.status === "error") {
                              throw new Error(result.message);
                            }

                            await fetchCategories();
                            setError(null);
                          } catch (error) {
                            console.error("Error deleting category:", error);
                            setError(
                              error.message || "Failed to delete category"
                            );
                          }
                        }
                      }}
                    >
                      <Trash2 size={20} />
                    </button>
                  </div>
                </>
              )}
            </div>
          ))
        ) : (
          <div className="text-center p-4 text-gray-500">
            {error ? "Error loading categories" : "No categories found"}
          </div>
        )}
      </div>
    </div>
  );
};

const EditCategoryForm = ({ category, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    name: category.name,
    description: category.description,
    icon: null,
  });
  const [previewImage, setPreviewImage] = useState(
    category.icon ? category.icon_url : null
  );

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        alert("File size must be less than 5MB");
        return;
      }

      // Check file type
      const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!allowedTypes.includes(file.type)) {
        alert("Only JPG, PNG and GIF files are allowed");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFormData((prev) => ({ ...prev, icon: file }));
    }
  };

  return (
    <form
      className="edit-form"
      onSubmit={(e) => {
        e.preventDefault();
        onSave(formData);
      }}
    >
      <input
        type="text"
        value={formData.name}
        onChange={(e) =>
          setFormData((prev) => ({ ...prev, name: e.target.value }))
        }
        required
        className="focus:ring-2 focus:ring-indigo-500"
      />
      <input
        type="text"
        placeholder="Description"
        value={formData.description}
        onChange={(e) =>
          setFormData((prev) => ({ ...prev, description: e.target.value }))
        }
        className="focus:ring-2 focus:ring-indigo-500"
      />
      <div className="image-upload">
        <label className="upload-button">
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="hidden"
          />
          <Upload size={20} />
          Change Icon
        </label>
        {previewImage && (
          <div className="image-preview">
            <img
              src={previewImage}
              alt="Preview"
              className="max-w-24 max-h-24 object-contain"
            />
          </div>
        )}
      </div>
      <div className="actions">
        <button
          type="button"
          onClick={onCancel}
          className="p-2 hover:bg-gray-100 rounded-full"
        >
          <X size={20} />
        </button>
        <button
          type="submit"
          className="p-2 hover:bg-green-100 rounded-full text-green-600"
        >
          <Check size={20} />
        </button>
      </div>
    </form>
  );
};

export default CategoryManager;