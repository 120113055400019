// components/Wiki.jsx
import React, { useState, useEffect } from 'react';
import {
  Search,
  Menu,
  X,
  Scroll,
  ChevronDown,
  ChevronUp
} from 'lucide-react';
import { Card, CardContent } from './ui/card';
import Category from './Wiki/Category';
import '../style/Wiki.scss';

const Wiki = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [expandedCategories, setExpandedCategories] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        // First fetch categories
        const categoriesResponse = await fetch('https://playninjarift.com/server/admin/get_categories.php');
        const categoriesData = await categoriesResponse.json();

        if (categoriesData.status === 'success') {
          // For each category, fetch its subcategories
          const categoriesWithSubs = await Promise.all(
            categoriesData.data.map(async (category) => {
              try {
                const subResponse = await fetch(
                  `https://playninjarift.com/server/admin/get_subcategories.php?category_id=${category.category_id}`
                );
                const subData = await subResponse.json();

                return {
                  id: category.category_id,
                  name: category.name,
                  description: category.description,
                  icon: category.icon_url ? (
                    <img
                      src={category.icon_url}
                      alt={category.name}
                      className="w-full h-full object-contain"
                    />
                  ) : (
                    <Scroll className="w-full h-full" />
                  ),
                  icon_url: category.icon_url,
                  subCategories: subData.status === 'success' ?
                    sortSubCategories(subData.data) : []
                };
              } catch (err) {
                console.error(`Error fetching subcategories for category ${category.category_id}:`, err);
                return {
                  id: category.category_id,
                  name: category.name,
                  description: category.description,
                  icon: category.icon_url ? (
                    <img
                      src={category.icon_url}
                      alt={category.name}
                      className="w-full h-full object-contain"
                    />
                  ) : (
                    <Scroll className="w-full h-full" />
                  ),
                  icon_url: category.icon_url,
                  subCategories: []
                };
              }
            })
          );

          setCategories(categoriesWithSubs);
        } else {
          throw new Error(categoriesData.message || 'Failed to fetch categories');
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load categories. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const sortSubCategories = (subcategories) => {
    return [...subcategories].sort((a, b) => {
      const numA = parseInt(a.name.match(/\d+/)?.[0] || 0);
      const numB = parseInt(b.name.match(/\d+/)?.[0] || 0);
      return numA - numB;
    });
  };

  const shouldShowExpand = (subcategories) => {
    return subcategories?.length > 6;
  };

  const getVisibleSubCategories = (categoryId, subcategories) => {
    if (!subcategories) return [];
    if (expandedCategories[categoryId]) {
      return subcategories;
    }
    return subcategories.slice(0, 6);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCategoryClick = (categoryId) => {
    setSelectedCategoryId(categoryId);
    setSelectedSubCategory(null);
  };

  const handleSubCategoryClick = (e, subcategory) => {
    e.stopPropagation();
    setSelectedCategoryId(subcategory.category_id);
    setSelectedSubCategory(subcategory);
  };

  const toggleExpand = (categoryId, e) => {
    e.stopPropagation();
    setExpandedCategories(prev => ({
      ...prev,
      [categoryId]: !prev[categoryId]
    }));
  };

  const filteredCategories = categories.filter(category => {
    const query = searchQuery.toLowerCase();
    return (
      category.name.toLowerCase().includes(query) ||
      category.description?.toLowerCase().includes(query) ||
      category.subCategories?.some(sub =>
        sub.name.toLowerCase().includes(query) ||
        sub.description?.toLowerCase().includes(query)
      )
    );
  });

  if (isLoading) {
    return (
      <div className="loading-screen">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="wiki-container">
      {/* Navigation */}
      <nav className="wiki-nav">
        <div className="nav-content">
          <div className="nav-wrapper">
            <div className="nav-left">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="mobile-menu-button"
              >
                {isMenuOpen ? <X /> : <Menu />}
              </button>
              <h1>Ninja Rift Wiki</h1>
            </div>

            <div className="nav-right">
              <div className="search-container">
                <Search className="search-icon" />
                <input
                  type="search"
                  placeholder="Search wiki..."
                  className="search-input"
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="main-content">
        {error ? (
          <div className="error-message">{error}</div>
        ) : selectedCategoryId ? (
          <Category
            categoryId={selectedCategoryId}
            initialSelectedSubcategory={selectedSubCategory}
            onBack={() => {
              setSelectedCategoryId(null);
              setSelectedSubCategory(null);
            }}
          />
        ) : (
          <>
            {/* Hero Section */}
            <div className="hero-section">
              <h2>Welcome to Ninja Rift</h2>
              <p>Your ultimate guide to mastering the art of the ninja</p>
            </div>

            {/* Categories Grid */}
            <div className="categories-grid">
              {filteredCategories.map((category) => (
                <Card
                  key={category.id}
                  className="category-card"
                  onClick={() => handleCategoryClick(category.id)}
                >
                  <CardContent className="category-content">
                    <div className="category-wrapper">
                      <div className="category-icon">
                        {category.icon}
                      </div>
                      <div className="category-info">
                        <h3>{category.name}</h3>
                        <p>{category.description}</p>
                        {category.subCategories && category.subCategories.length > 0 && (
                          <div className="subcategories-content">
                            <div className="subcategories-list">
                              {getVisibleSubCategories(category.id, category.subCategories)
                                .map((sub) => (
                                  <span
                                    key={sub.subcategory_id}
                                    className="subcategory-tag"
                                    onClick={(e) => handleSubCategoryClick(e, sub)}
                                  >
                                    {sub.name}
                                  </span>
                                ))}
                            </div>
                            {shouldShowExpand(category.subCategories) && (
                              <button
                                className="expand-button"
                                onClick={(e) => toggleExpand(category.id, e)}
                              >
                                {expandedCategories[category.id] ? (
                                  <>
                                    <ChevronUp className="w-4 h-4" />
                                    Show Less
                                  </>
                                ) : (
                                  <>
                                    <ChevronDown className="w-4 h-4" />
                                    Show More
                                  </>
                                )}
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          </>
        )}
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="mobile-menu-overlay">
          <div className="mobile-menu">
            <div className="mobile-menu-header">
              <h2>Menu</h2>
              <button onClick={() => setIsMenuOpen(false)}>
                <X />
              </button>
            </div>
            <div className="mobile-menu-items">
              {categories.map((category) => (
                <div
                  key={category.id}
                  className="mobile-menu-item"
                  onClick={() => {
                    handleCategoryClick(category.id);
                    setIsMenuOpen(false);
                  }}
                >
                  {category.icon}
                  <span>{category.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Wiki;