import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './strategu.scss';
import ServerSettings from './ServerSettings';
import CharacterLookup from './CharacterLookup';
import ItemManager from './ItemManager';

const Strategu = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('server');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');

  useEffect(() => {
    const checkAuth = async () => {
      const sessionData = localStorage.getItem('strategicSession');
      if (!sessionData) {
        navigate('/strategu/login');
        return;
      }

      try {
        const { acc_id, web_session_key, username } = JSON.parse(sessionData);
        
        const formData = new URLSearchParams();
        formData.append('acc_id', acc_id);
        formData.append('web_session_key', web_session_key);
        
        const response = await fetch('https://ninjarift.org/adminpanel/login.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formData
        });

        const data = await response.json();
        
        if (!data.valid) {
          localStorage.removeItem('strategicSession');
          navigate('/strategu/login');
          return;
        }

        setUsername(username);
        setIsAuthenticated(true);
      } catch (err) {
        console.error('Session verification error:', err);
        localStorage.removeItem('strategicSession');
        navigate('/strategu/login');
      }
    };

    checkAuth();

    // Set up periodic session check
    const checkInterval = setInterval(checkAuth, 60000); // Check every minute

    return () => clearInterval(checkInterval);
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('strategicSession');
    navigate('/strategu/login');
  };

  if (!isAuthenticated) {
    return <div>Loading...</div>;
  }

  return (
    <div className="content-container">
      <div className="h1-dashboard">
        <div className="title-section">
          <span className="title">Developer Dashboard</span>
          <span className="username">Welcome, {username}</span>
        </div>
        <button className="logout-btn" onClick={handleLogout}>Logout</button>
      </div>

      <div className="data-container">
        <div className="tabs">
          <button 
            className={activeTab === 'server' ? 'active' : ''} 
            onClick={() => setActiveTab('server')}
          >
            Server Settings
          </button>
          <button 
            className={activeTab === 'characters' ? 'active' : ''} 
            onClick={() => setActiveTab('characters')}
          >
            Character Lookup
          </button>
          <button 
            className={activeTab === 'items' ? 'active' : ''} 
            onClick={() => setActiveTab('items')}
          >
            Items
          </button>
        </div>

        {activeTab === 'server' && <ServerSettings />}
        {activeTab === 'characters' && <CharacterLookup />}
        {activeTab === 'items' && <ItemManager />}
      </div>
    </div>
  );
};

export default Strategu;
