import React, { createContext, useContext, useState } from 'react';
import $ from 'jquery';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const acc_id = localStorage.getItem("acc_id");
    const web_session_key = localStorage.getItem("web_session_key");
    return !!(acc_id && web_session_key);
  });

  const login = async () => {
    setIsLoggedIn(true);
    return Promise.resolve(); // Make it awaitable
  };

  const logout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};