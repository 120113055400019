import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  CssBaseline,
  Paper,
  CircularProgress,
  Link,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useAuth } from "../context/AuthContext";
import VerificationCodePopup from "./VerificationCodePopup";
import ResetPasswordPopup from "./ResetPasswordPopup";

const darkBlueTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#111827",
      paper: "#1c1f26",
    },
    text: {
      primary: "#c9d1d9",
      secondary: "#8b949e",
    },
    primary: {
      main: "#1e3a8a",
    },
    secondary: {
      main: "#3b82f6",
    },
  },
  typography: {
    fontSize: 16,
    h1: {
      fontSize: '2.4rem',
    },
    h5: {
      fontSize: '1.8rem',
    },
    body1: {
      fontSize: '1.2rem',
    },
    body2: {
      fontSize: '1rem',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#262a34",
          borderRadius: 8,
          fontSize: '1.2rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1.2rem',
        },
      },
    },
  },
});

function Login() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const MySwal = withReactContent(Swal);
  const [emailError, setEmailError] = useState(false);
  const [isVerificationOpen, setIsVerificationOpen] = useState(false);
  const [isResetPasswordOpen, setIsResetPasswordOpen] = useState(false);
  const [pendingVerification, setPendingVerification] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Check for existing session
    const acc_id = localStorage.getItem("acc_id");
    const web_session_key = localStorage.getItem("web_session_key");
    
    if (acc_id && web_session_key) {
      setIsLoading(true);
      $.ajax({
        type: "POST",
        url: "https://ninjarift.org/account/VerifyAccount.php",
        data: {
          acc_id: acc_id,
          web_session_key: web_session_key
        },
        success(data) {
          if (data.valid) {
            login();
            navigate("/dashboard", { replace: true });
          } else {
            localStorage.clear();
          }
          setIsLoading(false);
        },
        error() {
          localStorage.clear();
          setIsLoading(false);
        }
      });
    }
  }, [navigate, login]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);

    const email = form.find("#mail").val();
    if (!validateEmail(email)) {
      setEmailError(true);
      return;
    } else {
      setEmailError(false);
    }

    setIsLoading(true);
    console.log("Submitting login form...");

    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        console.log("Login response:", data);
        setIsLoading(false);
        if (data.needs_verification) {
          setPendingVerification({
            acc_id: data.acc_id,
            username: data.username
          });
          setIsVerificationOpen(true);
        } else if (data.error) {
          MySwal.fire({
            title: <strong>An Error Occurred!</strong>,
            html: <i>{data.error}</i>,
            icon: 'error'
          });
        } else {
          MySwal.fire({
            title: <strong>An Error Occurred!</strong>,
            html: <i>Invalid response from server</i>,
            icon: 'error'
          });
        }
      },
      error() {
        console.error("Login request failed");
        setIsLoading(false);
        MySwal.fire({
          title: <strong>An Error Occurred!</strong>,
          html: <i>Internal error!</i>,
          icon: 'error'
        });
      }
    });
  };

  const handleVerificationSuccess = async (data) => {
    try {
      console.log('Setting localStorage data...', data);
      
      // Set all localStorage items
      localStorage.setItem("acc_id", data.acc_id);
      localStorage.setItem("username", data.username);
      localStorage.setItem("web_session_key", data.web_session_key);
      localStorage.setItem("tokens", data.tokens);
      localStorage.setItem("characters", JSON.stringify(data.characters || []));

      const accType = data.acc_type === 2 ? "Emblem Plus User" : 
                    data.acc_type === 1 ? "Premium User" : "Free User";
      localStorage.setItem("acc_type", accType);

      // First close verification popup and update auth state
      setIsVerificationOpen(false);
      await login();

      console.log('Stored data:', {
        acc_id: localStorage.getItem("acc_id"),
        web_session_key: localStorage.getItem("web_session_key")
      });

      // Then navigate
      console.log('All data stored, navigating to dashboard...');
      navigate("/dashboard", { replace: true });
    } catch (error) {
      console.error('Error during verification success:', error);
      MySwal.fire({
        title: <strong>Login Error</strong>,
        html: <i>Failed to process login. Please try again.</i>,
        icon: 'error'
      });
    }
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(email).toLowerCase());
  };

  const closeVerificationPopup = () => {
    setIsVerificationOpen(false);
    setPendingVerification(null);
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setIsResetPasswordOpen(true);
  };

  return (
    <ThemeProvider theme={darkBlueTheme}>
      <CssBaseline />
      <Container component="main" maxWidth="md" sx={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box
          component={Paper}
          elevation={8}
          p={6}
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography component="h1" variant="h5">
            Sign In
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            Please enter your credentials to access your account
          </Typography>
          <Box component="form" action="https://ninjarift.org/account/Login.php" method="post" onSubmit={handleSubmit} noValidate sx={{ mt: 4, width: "100%" }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="mail"
              label="Email Address"
              name="mail"
              autoComplete="email"
              autoFocus
              error={emailError}
              helperText={emailError ? "Please enter a valid email." : ""}
              InputLabelProps={{ style: { color: "#8b949e" } }}
              disabled={isLoading}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              InputLabelProps={{ style: { color: "#8b949e" } }}
              disabled={isLoading}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
              <Link
                href="#"
                onClick={handleForgotPassword}
                variant="body2"
                sx={{ 
                  color: 'secondary.main',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                Forgot Password?
              </Link>
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 4 }}
              color="primary"
              disabled={isLoading}
            >
              {isLoading ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CircularProgress size={24} sx={{ color: 'white', mr: 1 }} />
                  Loading...
                </Box>
              ) : (
                'Login'
              )}
            </Button>
          </Box>
        </Box>
        {isVerificationOpen && pendingVerification && (
          <VerificationCodePopup
            open={isVerificationOpen}
            onClose={closeVerificationPopup}
            accountId={pendingVerification.acc_id}
            username={pendingVerification.username}
            onVerificationSuccess={handleVerificationSuccess}
          />
        )}
        <ResetPasswordPopup
          open={isResetPasswordOpen}
          onClose={() => setIsResetPasswordOpen(false)}
        />
      </Container>
    </ThemeProvider>
  );
}

export default Login;
