import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import logo from "../img/logo.png";
import "../style/Header.scss";

function Header() {
  const { isLoggedIn } = useAuth();

  return (
    <nav className="navbar navbar-expand-md">
      <div className="navbar-content">
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link to="/" className="nav-link">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/PatchNotes" className="nav-link">
              Patch Notes
            </Link>
          </li>
        </ul>
        <Link to="/" className="navbar-logo">
          <img src={logo} alt="logo" />
        </Link>
        <ul className="navbar-nav">
          <li className="nav-item dropdown">
            <button
              className="nav-link dropdown-toggle"
              id="rankingsDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Rankings
            </button>
            <div className="dropdown-menu" aria-labelledby="rankingsDropdown">
              <Link to="/ClanRanking" className="dropdown-item">
                Clan Rankings
              </Link>
              <Link to="/CrewRanking" className="dropdown-item">
                Crew Rankings
              </Link>
              <Link to="/ArenaRanking" className="dropdown-item">
                Arena Rankings
              </Link>
            </div>
          </li>
          {!isLoggedIn && (
            <li className="nav-item">
              <Link to="/Login" className="nav-link">
                Login
              </Link>
            </li>
          )}
          {isLoggedIn && (
            <li className="nav-item">
              <Link to="/Dashboard" className="nav-link">
                Dashboard
              </Link>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
}

export default Header;
