import React, { useState } from 'react';
import './CharacterLookup.scss';

const CharacterLookup = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchType, setSearchType] = useState('character_id');
  const [searchResults, setSearchResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!searchQuery.trim()) return;

    setIsLoading(true);
    setStatusMessage('');
    setSearchResults(null);

    const sessionData = localStorage.getItem('strategicSession');
    if (!sessionData) return;

    try {
      const { acc_id, web_session_key } = JSON.parse(sessionData);
      const formData = new URLSearchParams();
      formData.append('acc_id', acc_id);
      formData.append('web_session_key', web_session_key);
      formData.append('action', 'search_character');
      formData.append('search_type', searchType);
      formData.append('search_query', searchQuery);

      const response = await fetch('https://ninjarift.org/adminpanel/dashboard.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        setSearchResults(data.characters);
        if (data.characters.length === 0) {
          setStatusMessage('No characters found');
        }
      } else {
        setStatusMessage(data.error || 'Error searching characters');
      }
    } catch (err) {
      console.error('Error searching characters:', err);
      setStatusMessage('Error searching characters');
    } finally {
      setIsLoading(false);
    }
  };

  const handleBanCharacter = async (characterId, reason) => {
    if (!reason.trim()) {
      setStatusMessage('Please provide a reason for the ban');
      return;
    }

    setIsLoading(true);
    setStatusMessage('');

    const sessionData = localStorage.getItem('strategicSession');
    if (!sessionData) return;

    try {
      const { acc_id, web_session_key } = JSON.parse(sessionData);
      const formData = new URLSearchParams();
      formData.append('acc_id', acc_id);
      formData.append('web_session_key', web_session_key);
      formData.append('action', 'ban_character');
      formData.append('character_id', characterId);
      formData.append('reason', reason);

      const response = await fetch('https://ninjarift.org/adminpanel/dashboard.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        setStatusMessage('Character banned successfully');
        // Refresh search results
        handleSearch(new Event('submit'));
      } else {
        setStatusMessage(data.error || 'Error banning character');
      }
    } catch (err) {
      console.error('Error banning character:', err);
      setStatusMessage('Error banning character');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnbanCharacter = async (characterId) => {
    setIsLoading(true);
    setStatusMessage('');

    const sessionData = localStorage.getItem('strategicSession');
    if (!sessionData) return;

    try {
      const { acc_id, web_session_key } = JSON.parse(sessionData);
      const formData = new URLSearchParams();
      formData.append('acc_id', acc_id);
      formData.append('web_session_key', web_session_key);
      formData.append('action', 'unban_character');
      formData.append('character_id', characterId);

      const response = await fetch('https://ninjarift.org/adminpanel/dashboard.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        setStatusMessage('Character unbanned successfully');
        // Refresh search results
        handleSearch(new Event('submit'));
      } else {
        setStatusMessage(data.error || 'Error unbanning character');
      }
    } catch (err) {
      console.error('Error unbanning character:', err);
      setStatusMessage('Error unbanning character');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="character-lookup-panel">
      <div className="data-item">
        <label>Character Lookup</label>
        <div className="search-controls">
          <form onSubmit={handleSearch}>
            <div className="search-inputs">
              <select 
                value={searchType}
                onChange={(e) => setSearchType(e.target.value)}
                className="search-type"
              >
                <option value="character_id">Character ID</option>
                <option value="account_id">Account ID</option>
                <option value="username">Username</option>
                <option value="name">Character Name</option>
                <option value="xp">XP</option>
              </select>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder={`Search by ${searchType.replace('_', ' ')}...`}
                className="search-input"
              />
              <button 
                type="submit"
                className="search-btn"
                disabled={isLoading}
              >
                {isLoading ? 'Searching...' : 'Search'}
              </button>
            </div>
          </form>
        </div>
      </div>

      {searchResults && searchResults.length > 0 && (
        <div className="data-item">
          <label>Search Results</label>
          <div className="search-results">
            {searchResults.map((character) => (
              <div key={character.id} className="character-card">
                <div className="character-info">
                  <h3>{character.name}</h3>
                  <div className="info-grid">
                    <div className="info-item">
                      <span>Character ID:</span>
                      <strong>{character.id}</strong>
                    </div>
                    <div className="info-item">
                      <span>Account ID:</span>
                      <strong>{character.account_id}</strong>
                    </div>
                    <div className="info-item">
                      <span>Username:</span>
                      <strong>{character.username}</strong>
                    </div>
                    <div className="info-item">
                      <span>XP:</span>
                      <strong>{character.xp}</strong>
                    </div>
                    <div className="info-item">
                      <span>Status:</span>
                      <strong className={character.banned ? 'banned' : 'active'}>
                        {character.banned ? 'Banned' : 'Active'}
                      </strong>
                    </div>
                  </div>
                </div>
                <div className="character-actions">
                  {character.banned ? (
                    <button
                      onClick={() => handleUnbanCharacter(character.id)}
                      className="unban-btn"
                      disabled={isLoading}
                    >
                      Unban Character
                    </button>
                  ) : (
                    <div className="ban-controls">
                      <input
                        type="text"
                        placeholder="Reason for ban..."
                        className="ban-reason"
                      />
                      <button
                        onClick={(e) => handleBanCharacter(
                          character.id,
                          e.target.previousElementSibling.value
                        )}
                        className="ban-btn"
                        disabled={isLoading}
                      >
                        Ban Character
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {statusMessage && (
        <div className={`status-message ${statusMessage.includes('Error') ? 'error' : 'success'}`}>
          {statusMessage}
        </div>
      )}
    </div>
  );
};

export default CharacterLookup;
