import { Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Landing from "./components/Landing";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import About from "./components/About";
import PatchNotes from "./components/PatchNotes";
import ClanRanking from "./components/ClanRanking";
import CrewRanking from "./components/CrewRanking";
import ArenaRanking from "./components/ArenaRanking";
import Wiki from "./components/Wiki";
import Package from "./components/Package";
import Tokens from "./components/Tokens";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import AdminLogin from "./components/admin/AdminLogin";
import AdminPanel from "./components/admin/AdminPanel";
import Strategu from "./components/strategu/strategu";
import StrateguLogin from "./components/strategu/login";
import "./App.scss";

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/About" element={<About />} />
          <Route path="/PatchNotes" element={<PatchNotes />} />
          <Route path="/ClanRanking" element={<ClanRanking />} />
          <Route path="/CrewRanking" element={<CrewRanking />} />
          <Route path="/ArenaRanking" element={<ArenaRanking />} />
          <Route path="/Wiki" element={<Wiki />} />
          <Route path="/Package" element={<Package />} />
          <Route path="/Tokens" element={<Tokens />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
          <Route path="/admin/AdminLogin" element={<AdminLogin />} />
          <Route path="/admin/AdminPanel" element={<AdminPanel />} />
          <Route path="/strategu" element={<Strategu />} />
          <Route path="/strategu/login" element={<StrateguLogin />} />
        </Routes>
        <Footer />
      </div>
    </AuthProvider>
  );
}

export default App;
