import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './login.scss';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [needsVerification, setNeedsVerification] = useState(false);
  const [accountData, setAccountData] = useState(null);

  useEffect(() => {
    // Check existing session on component mount
    const checkSession = async () => {
      const sessionData = localStorage.getItem('strategicSession');
      if (sessionData) {
        const { acc_id, web_session_key } = JSON.parse(sessionData);
        
        try {
          const response = await fetch('https://ninjarift.org/adminpanel/login.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `acc_id=${encodeURIComponent(acc_id)}&web_session_key=${encodeURIComponent(web_session_key)}`
          });

          const data = await response.json();
          
          if (data.valid) {
            navigate('/strategu');
          } else {
            localStorage.removeItem('strategicSession');
          }
        } catch (err) {
          console.error('Session check error:', err);
          localStorage.removeItem('strategicSession');
        }
      }
    };

    checkSession();
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch('https://ninjarift.org/adminpanel/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `mail=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`
      });

      const data = await response.json();

      if (data.error) {
        setError(data.error);
        return;
      }

      if (data.needs_verification) {
        setNeedsVerification(true);
        setAccountData({
          acc_id: data.acc_id,
          username: data.username
        });
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('Failed to connect to server');
    }
  };

  const handleVerification = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch('https://ninjarift.org/adminpanel/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `code=${encodeURIComponent(verificationCode)}&acc_id=${encodeURIComponent(accountData.acc_id)}`
      });

      const data = await response.json();

      if (data.error) {
        setError(data.error);
        return;
      }

      if (data.verified) {
        // Store session data
        const sessionData = {
          acc_id: accountData.acc_id,
          username: data.username,
          web_session_key: data.web_session_key
        };
        localStorage.setItem('strategicSession', JSON.stringify(sessionData));
        navigate('/strategu');
      } else {
        setError('Verification failed');
      }
    } catch (err) {
      console.error('Verification error:', err);
      setError('Failed to verify code');
    }
  };

  return (
    <div className="strategic-login">
      <div className="login-container">
        <h1>Developer Login</h1>
        {error && <div className="error-message">{error}</div>}
        
        {!needsVerification ? (
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit">Login</button>
          </form>
        ) : (
          <form onSubmit={handleVerification}>
            <div className="verification-message">
              A verification code has been sent to your email.
            </div>
            <div className="form-group">
              <label htmlFor="verificationCode">Verification Code</label>
              <input
                type="text"
                id="verificationCode"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder="Enter 6-digit code"
                maxLength="6"
                required
              />
            </div>
            <button type="submit">Verify</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
