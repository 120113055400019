import "../style/Package.scss";
import Swal from 'sweetalert2';
import { useState } from 'react';

function Package({ 
  name, 
  price, 
  get, 
  discount, 
  packageId,
  username,
  accountId,
  charId,
  disabled
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handlePayment = () => {
    if (disabled || !charId) {
      Swal.fire({
        icon: 'warning',
        title: 'Character Required',
        text: 'Please select a character before making a purchase.',
      });
      return;
    }

    try {
      setIsLoading(true);

      const form = document.createElement('form');
      form.method = 'post';
      form.action = 'https://www.paypal.com/cgi-bin/webscr';
      
      const customData = {
        username: username,
        accountId: accountId,
        packageid: packageId,
        charid: charId,
        timestamp: new Date().toISOString()
      };
      
      const fields = {
        cmd: '_xclick',
        business: 'ninjariftofficial@gmail.com',
        item_name: `${name} for ${username} (Character: ${charId})`,
        amount: Number(price).toFixed(2),
        currency_code: 'USD',
        return: 'https://playninjarift.com',
        cancel_return: 'https://playninjarift.com',
        notify_url: 'https://ninjarift.org/paypal/paypal_webhook.php',
        no_shipping: '1',
        no_note: '1',
        rm: '2',
        custom: JSON.stringify(customData),
        item_number: packageId,
        invoice: `${packageId}_${Date.now()}_${Math.random().toString(36).substr(2, 5)}`
      };

      // Log the data being sent
      console.log('PayPal checkout data:', fields);

      Object.entries(fields).forEach(([key, value]) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = value;
        form.appendChild(input);
      });

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);

    } catch (error) {
      console.error('Payment Error:', error);
      setIsLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Payment Failed',
        text: 'There was an error processing your payment. Please try again.',
      });
    }
  };

  return (
    <div className={`package-container ${disabled ? 'disabled' : ''}`}>
      <div className="package-name">
        <div className="package-text">
          <h3>{name}</h3>
          <p>{get}</p>
        </div>
        {discount && (
          <div className="discount">
            <h3>{discount}</h3>
            <p>off</p>
          </div>
        )}
      </div>

      <div className="price-container">
        <div className="price-text">
          <h3>{Number(price).toFixed(2)}</h3>
          <p>USD</p>
        </div>
        <div>
          <button 
            onClick={handlePayment} 
            className={`buy-button ${isLoading ? 'loading' : ''} ${disabled ? 'disabled' : ''}`}
            disabled={isLoading || disabled}
          >
            {isLoading ? 'Processing...' : disabled ? 'Select Character' : 'Buy with PayPal'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Package;
