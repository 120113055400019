import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import $ from "jquery";

const textColor = "#8b949e";

const textFieldStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': { borderColor: textColor },
    '&:hover fieldset': { borderColor: textColor },
  },
  '& .MuiInputBase-input': { color: textColor },
  '& .MuiInputLabel-root': { color: textColor },
  '& .MuiInputLabel-root.Mui-focused': { color: textColor },
  '& .MuiInputLabel-root.MuiFormLabel-filled': { color: textColor }
};

function ResetPasswordPopup({ open, onClose }) {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [codeError, setCodeError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    if (open) {
      resetForm();
      setStep(1);
    }
  }, [open]);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(email).toLowerCase());
  };

  const resetForm = () => {
    setEmail("");
    setResetCode("");
    setNewPassword("");
    setConfirmPassword("");
    setEmailError("");
    setCodeError("");
    setPasswordError("");
  };

  const handleSendCode = () => {
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      return;
    }
    setEmailError("");
    setIsLoading(true);

    $.ajax({
      type: "POST",
      url: "https://ninjarift.org/account/ResetPassword.php",
      data: {
        action: "sendRecoveryCode",
        email: email
      },
      success(data) {
        setIsLoading(false);
        if (data.status === 1) {
          setStep(2);
        } else {
          setEmailError(data.error || data.result);
        }
      },
      error() {
        setIsLoading(false);
        setEmailError("Failed to send recovery code");
      }
    });
  };

  const handleVerifyCode = () => {
    if (!resetCode) {
      setCodeError("Please enter the recovery code");
      return;
    }
    setCodeError("");
    setIsLoading(true);

    $.ajax({
      type: "POST",
      url: "https://ninjarift.org/account/ResetPassword.php",
      data: {
        action: "verifyRecoveryCode",
        email: email,
        resetCode: resetCode
      },
      success(data) {
        setIsLoading(false);
        if (data.status === 1) {
          setStep(3);
        } else {
          setCodeError(data.error || data.result);
        }
      },
      error() {
        setIsLoading(false);
        setCodeError("Failed to verify code");
      }
    });
  };

  const handleResetPassword = () => {
    if (newPassword !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }

    if (newPassword.length < 6) {
      setPasswordError("Password must be at least 6 characters long");
      return;
    }

    setPasswordError("");
    setIsLoading(true);

    $.ajax({
      type: "POST",
      url: "https://ninjarift.org/account/ResetPassword.php",
      data: {
        action: "resetPassword",
        email: email,
        resetCode: resetCode,
        newPassword: newPassword
      },
      success(data) {
        setIsLoading(false);
        if (data.status === 1) {
          setStep(4);
        } else {
          setPasswordError(data.error || data.result);
        }
      },
      error() {
        setIsLoading(false);
        setPasswordError("Failed to reset password");
      }
    });
  };

  const handleClose = () => {
    resetForm();
    setStep(1);
    onClose();
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <DialogTitle sx={{ color: textColor }}>Reset Password</DialogTitle>
            <DialogContent>
              <Typography variant="body2" sx={{ mb: 2, color: textColor }}>
                Enter your email address to receive a recovery code
              </Typography>
              <TextField
                autoFocus
                margin="dense"
                label="Email Address"
                type="email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!emailError}
                helperText={emailError}
                disabled={isLoading}
                sx={textFieldStyle}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} disabled={isLoading} sx={{ color: textColor }}>Cancel</Button>
              <Button onClick={handleSendCode} disabled={isLoading} sx={{ color: textColor }}>
                {isLoading ? (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CircularProgress size={24} sx={{ color: textColor, mr: 1 }} />
                    Sending...
                  </Box>
                ) : (
                  'Send Code'
                )}
              </Button>
            </DialogActions>
          </>
        );
      case 2:
        return (
          <>
            <DialogTitle sx={{ color: textColor }}>Verify Code</DialogTitle>
            <DialogContent>
              <Typography variant="body2" sx={{ mb: 2, color: textColor }}>
                Enter the recovery code sent to your email
              </Typography>
              <TextField
                autoFocus
                margin="dense"
                label="Recovery Code"
                type="text"
                fullWidth
                value={resetCode}
                onChange={(e) => setResetCode(e.target.value)}
                error={!!codeError}
                helperText={codeError}
                disabled={isLoading}
                sx={textFieldStyle}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setStep(1)} disabled={isLoading} sx={{ color: textColor }}>Back</Button>
              <Button onClick={handleVerifyCode} disabled={isLoading} sx={{ color: textColor }}>
                {isLoading ? (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CircularProgress size={24} sx={{ color: textColor, mr: 1 }} />
                    Verifying...
                  </Box>
                ) : (
                  'Verify Code'
                )}
              </Button>
            </DialogActions>
          </>
        );
      case 3:
        return (
          <>
            <DialogTitle sx={{ color: textColor }}>Set New Password</DialogTitle>
            <DialogContent>
              <Typography variant="body2" sx={{ mb: 2, color: textColor }}>
                Enter your new password
              </Typography>
              <TextField
                autoFocus
                margin="dense"
                label="New Password"
                type="password"
                fullWidth
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                error={!!passwordError}
                disabled={isLoading}
                sx={textFieldStyle}
              />
              <TextField
                margin="dense"
                label="Confirm Password"
                type="password"
                fullWidth
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={!!passwordError}
                helperText={passwordError}
                disabled={isLoading}
                sx={textFieldStyle}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setStep(2)} disabled={isLoading} sx={{ color: textColor }}>Back</Button>
              <Button onClick={handleResetPassword} disabled={isLoading} sx={{ color: textColor }}>
                {isLoading ? (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CircularProgress size={24} sx={{ color: textColor, mr: 1 }} />
                    Resetting...
                  </Box>
                ) : (
                  'Reset Password'
                )}
              </Button>
            </DialogActions>
          </>
        );
      case 4:
        return (
          <>
            <DialogTitle sx={{ color: textColor }}>Success!</DialogTitle>
            <DialogContent>
              <Typography variant="body2" sx={{ mb: 2, color: textColor }}>
                Your password has been successfully changed. You can now log in with your new password.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} sx={{ color: textColor }}>Close</Button>
            </DialogActions>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose} 
      maxWidth="sm" 
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: '#1c1f26',
          '& .MuiDialogContent-root': {
            paddingTop: 2
          }
        }
      }}
    >
      {renderStep()}
    </Dialog>
  );
}

export default ResetPasswordPopup;
