import React, { useState } from 'react';
import { 
  Plus, 
  Edit2, 
  Trash2, 
  Save,
  X,
  Eye
} from 'lucide-react';
import ReactMarkdown from 'react-markdown';

const ArticleManager = ({ searchQuery }) => {
  const [articles, setArticles] = useState([
    {
      id: 1,
      title: "Beginner's Guide",
      content: "Welcome to Ninja Rift! This guide will help you...",
      category: "Guides",
      views: 0,
      lastModified: new Date().toISOString()
    },
    // Add more articles...
  ]);

  const [editingArticle, setEditingArticle] = useState(null);
  const [previewMode, setPreviewMode] = useState(false);

  const handleAddArticle = () => {
    const newArticle = {
      id: Date.now(),
      title: "New Article",
      content: "Start writing your article here...",
      category: "Uncategorized",
      views: 0,
      lastModified: new Date().toISOString()
    };
    setArticles([...articles, newArticle]);
    setEditingArticle(newArticle.id);
  };

  const handleSaveArticle = (article) => {
    setArticles(articles.map(a => 
      a.id === article.id ? { ...article, lastModified: new Date().toISOString() } : a
    ));
    setEditingArticle(null);
  };

  const handleDeleteArticle = (id) => {
    setArticles(articles.filter(article => article.id !== id));
  };

  const filteredArticles = articles.filter(article =>
    article.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    article.content.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="article-manager">
      <div className="section-header">
        <h2>Article Management</h2>
        <button className="add-button" onClick={handleAddArticle}>
          <Plus />
          <span>New Article</span>
        </button>
      </div>

      <div className="articles-list">
        {filteredArticles.map((article) => (
          <div key={article.id} className="article-item">
            {editingArticle === article.id ? (
              <div className="article-edit-form">
                <input
                  type="text"
                  value={article.title}
                  onChange={(e) => {
                    setArticles(articles.map(a =>
                      a.id === article.id ? { ...a, title: e.target.value } : a
                    ));
                  }}
                  placeholder="Article Title"
                />
                <select
                  value={article.category}
                  onChange={(e) => {
                    setArticles(articles.map(a =>
                      a.id === article.id ? { ...a, category: e.target.value } : a
                    ));
                  }}
                >
                  <option value="Guides">Guides</option>
                  <option value="Tutorials">Tutorials</option>
                  <option value="News">News</option>
                  <option value="Updates">Updates</option>
                </select>
                <div className="editor-toolbar">
                  <button
                    className={`preview-toggle ${previewMode ? 'active' : ''}`}
                    onClick={() => setPreviewMode(!previewMode)}
                  >
                    <Eye />
                    <span>Preview</span>
                  </button>
                </div>
                {previewMode ? (
                  <div className="markdown-preview">
                    <ReactMarkdown>{article.content}</ReactMarkdown>
                  </div>
                ) : (
                  <textarea
                    value={article.content}
                    onChange={(e) => {
                      setArticles(articles.map(a =>
                        a.id === article.id ? { ...a, content: e.target.value } : a
                      ));
                    }}
                    placeholder="Write your article content here... (Markdown supported)"
                  />
                )}
                <div className="form-actions">
                  <button
                    className="save"
                    onClick={() => handleSaveArticle(article)}
                  >
                    <Save />
                    <span>Save</span>
                  </button>
                  <button
                    className="cancel"
                    onClick={() => setEditingArticle(null)}
                  >
                    <X />
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
            ) : (
              <div className="article-info">
                <div className="article-header">
                  <h3>{article.title}</h3>
                  <span className="category-badge">{article.category}</span>
                </div>
                <div className="article-meta">
                  <span className="views">
                    <Eye className="w-4 h-4" />
                    {article.views} views
                  </span>
                  <span className="last-modified">
                    Last modified: {new Date(article.lastModified).toLocaleDateString()}
                  </span>
                </div>
                <div className="article-actions">
                  <button onClick={() => setEditingArticle(article.id)}>
                    <Edit2 />
                    <span>Edit</span>
                  </button>
                  <button onClick={() => handleDeleteArticle(article.id)}>
                    <Trash2 />
                    <span>Delete</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArticleManager;