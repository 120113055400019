import React, { useState, useEffect } from 'react';
import './ServerSettings.scss';

const ServerSettings = () => {
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [scheduledMaintenance, setScheduledMaintenance] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [serverTime, setServerTime] = useState('');
  const [scheduleForm, setScheduleForm] = useState({
    startTime: '',
    endTime: ''
  });
  const [clanSeasonInfo, setClanSeasonInfo] = useState({
    currentSeason: 0,
    isActive: false,
    endDate: '',
    resultsSaved: false
  });
  const [crewSeasonInfo, setCrewSeasonInfo] = useState({
    currentSeason: 0,
    isActive: false,
    endDate: '',
    resultsSaved: false,
    phase: 1,
    phase2EndDate: null
  });

  // Get current Chicago time in YYYY-MM-DDTHH:mm format for datetime-local input
  const getCurrentChicagoTime = () => {
    const now = new Date();
    const chicagoTime = new Date(now.toLocaleString('en-US', { timeZone: 'America/Chicago' }));
    
    const year = chicagoTime.getFullYear();
    const month = String(chicagoTime.getMonth() + 1).padStart(2, '0');
    const day = String(chicagoTime.getDate()).padStart(2, '0');
    const hours = String(chicagoTime.getHours()).padStart(2, '0');
    const minutes = String(chicagoTime.getMinutes()).padStart(2, '0');
    
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  // Format datetime-local value to server timestamp (treating input as Chicago time)
  const formatToServerTime = (timeStr) => {
    if (!timeStr) return '';
    // Create date assuming the input is in Chicago time
    const [datePart, timePart] = timeStr.split('T');
    return `${datePart} ${timePart}:00`;
  };

  // Update server time every second
  useEffect(() => {
    const updateServerTime = () => {
      const now = new Date();
      const chicagoTime = new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/Chicago',
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      }).format(now);
      setServerTime(chicagoTime);
    };

    updateServerTime();
    const interval = setInterval(updateServerTime, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchServerStatus();
    // Set up periodic status refresh
    const refreshInterval = setInterval(fetchServerStatus, 60000); // Refresh every minute
    return () => clearInterval(refreshInterval);
  }, []);

  const formatDateTime = (dateStr) => {
    if (!dateStr) return '';
    return new Date(dateStr).toLocaleString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  const getTimeFromNow = (dateStr) => {
    // Create a date object for the target time (which is already in Chicago time)
    const targetDate = new Date(dateStr);
    
    // Get current time in Chicago
    const now = new Date();
    const chicagoOffset = -6; // Chicago is UTC-6
    const localOffset = now.getTimezoneOffset() / 60;
    const offsetDiff = localOffset + chicagoOffset;
    
    // Adjust the current time to Chicago time by adding the offset difference
    const adjustedNow = new Date(now.getTime() + (offsetDiff * 60 * 60 * 1000));
    
    const diffTime = targetDate - adjustedNow;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const diffMinutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));

    if (diffDays > 0) {
      return `${diffDays}d ${diffHours}h from now`;
    } else if (diffHours > 0) {
      return `${diffHours}h ${diffMinutes}m from now`;
    } else if (diffMinutes > 0) {
      return `${diffMinutes} minutes from now`;
    } else {
      return 'Starting now';
    }
  };

  const getDuration = (startStr, endStr) => {
    const start = new Date(startStr);
    const end = new Date(endStr);
    const diffTime = end - start;
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
    const diffMinutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));

    if (diffHours > 0) {
      return `${diffHours}h ${diffMinutes}m`;
    } else {
      return `${diffMinutes} minutes`;
    }
  };

  const fetchServerStatus = async () => {
    const sessionData = localStorage.getItem('strategicSession');
    if (!sessionData) return;

    try {
      const { acc_id, web_session_key } = JSON.parse(sessionData);
      const formData = new URLSearchParams();
      formData.append('acc_id', acc_id);
      formData.append('web_session_key', web_session_key);
      formData.append('action', 'get_maintenance_status');

      const response = await fetch('https://ninjarift.org/adminpanel/dashboard.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData
      });

      const data = await response.json();
      console.log('Server status response:', data);

      if (data.success) {
        setMaintenanceMode(data.maintenance);
        setScheduledMaintenance(data.scheduled_maintenance);
        setClanSeasonInfo({
          currentSeason: data.clan_season,
          isActive: data.clan_season_active,
          endDate: data.clan_season_end,
          resultsSaved: data.results_saved
        });
        setCrewSeasonInfo({
          currentSeason: data.crew_season,
          isActive: data.crew_season_active,
          endDate: data.crew_season_end,
          resultsSaved: data.crew_results_saved,
          phase: data.crew_phase,
          phase2EndDate: data.crew_phase2_end_date
        });
        setStatusMessage('');
      } else {
        console.error('Server status error:', data.error);
        setStatusMessage(data.error || 'Error fetching server status');
      }
    } catch (err) {
      console.error('Error fetching server status:', err);
      setStatusMessage('Error fetching server status');
    }
  };

  const handleScheduleMaintenance = async (e) => {
    e.preventDefault();
    const sessionData = localStorage.getItem('strategicSession');
    if (!sessionData) return;

    setIsLoading(true);
    setStatusMessage('');

    try {
      const { acc_id, web_session_key } = JSON.parse(sessionData);
      const formData = new URLSearchParams();
      formData.append('acc_id', acc_id);
      formData.append('web_session_key', web_session_key);
      formData.append('action', 'schedule_maintenance');
      formData.append('start_time', formatToServerTime(scheduleForm.startTime));
      formData.append('end_time', formatToServerTime(scheduleForm.endTime));

      const response = await fetch('https://ninjarift.org/adminpanel/dashboard.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        setStatusMessage(data.message);
        setScheduleForm({ startTime: '', endTime: '' });
        fetchServerStatus();
      } else {
        setStatusMessage(data.error || 'Error scheduling maintenance');
      }
    } catch (err) {
      console.error('Error scheduling maintenance:', err);
      setStatusMessage('Error scheduling maintenance');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelSchedule = async () => {
    if (!scheduledMaintenance || scheduledMaintenance.status !== 'scheduled') return;
    
    const sessionData = localStorage.getItem('strategicSession');
    if (!sessionData) return;

    setIsLoading(true);
    setStatusMessage('');

    try {
      const { acc_id, web_session_key } = JSON.parse(sessionData);
      const formData = new URLSearchParams();
      formData.append('acc_id', acc_id);
      formData.append('web_session_key', web_session_key);
      formData.append('action', 'cancel_scheduled_maintenance');
      formData.append('schedule_id', scheduledMaintenance.id);

      const response = await fetch('https://ninjarift.org/adminpanel/dashboard.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        setStatusMessage(data.message);
        fetchServerStatus();
      } else {
        setStatusMessage(data.error || 'Error cancelling maintenance');
      }
    } catch (err) {
      console.error('Error cancelling maintenance:', err);
      setStatusMessage('Error cancelling maintenance');
    } finally {
      setIsLoading(false);
    }
  };

  const handleMaintenanceToggle = async () => {
    const sessionData = localStorage.getItem('strategicSession');
    if (!sessionData) return;

    setIsLoading(true);
    setStatusMessage('');

    try {
      const { acc_id, web_session_key } = JSON.parse(sessionData);
      const action = maintenanceMode ? 'disable_maintenance' : 'enable_maintenance';
      
      const formData = new URLSearchParams();
      formData.append('acc_id', acc_id);
      formData.append('web_session_key', web_session_key);
      formData.append('action', action);

      const response = await fetch('https://ninjarift.org/adminpanel/dashboard.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        setMaintenanceMode(!maintenanceMode);
        setStatusMessage(data.message);
      } else {
        setStatusMessage(data.error || 'Error toggling maintenance mode');
      }
    } catch (err) {
      console.error('Error toggling maintenance:', err);
      setStatusMessage('Error toggling maintenance mode');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEndClanSeason = async () => {
    const sessionData = localStorage.getItem('strategicSession');
    if (!sessionData) return;

    setIsLoading(true);
    setStatusMessage('');

    try {
      const { acc_id, web_session_key } = JSON.parse(sessionData);
      const formData = new URLSearchParams();
      formData.append('acc_id', acc_id);
      formData.append('web_session_key', web_session_key);
      formData.append('action', 'end_clan_season');

      const response = await fetch('https://ninjarift.org/adminpanel/dashboard.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        setStatusMessage(data.message);
        fetchServerStatus();
      } else {
        setStatusMessage(data.error || 'Error ending clan season');
      }
    } catch (err) {
      console.error('Error ending clan season:', err);
      setStatusMessage('Error ending clan season');
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartNewClanSeason = async () => {
    const sessionData = localStorage.getItem('strategicSession');
    if (!sessionData) return;

    setIsLoading(true);
    setStatusMessage('');

    try {
      const { acc_id, web_session_key } = JSON.parse(sessionData);
      const formData = new URLSearchParams();
      formData.append('acc_id', acc_id);
      formData.append('web_session_key', web_session_key);
      formData.append('action', 'start_new_clan_season');

      const response = await fetch('https://ninjarift.org/adminpanel/dashboard.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        setStatusMessage(data.message);
        fetchServerStatus();
      } else {
        setStatusMessage(data.error || 'Error starting new clan season');
      }
    } catch (err) {
      console.error('Error starting new clan season:', err);
      setStatusMessage('Error starting new clan season');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveResults = async () => {
    const sessionData = localStorage.getItem('strategicSession');
    if (!sessionData) return;

    setIsLoading(true);
    setStatusMessage('');

    try {
      const { acc_id, web_session_key } = JSON.parse(sessionData);
      const formData = new URLSearchParams();
      formData.append('acc_id', acc_id);
      formData.append('web_session_key', web_session_key);
      formData.append('action', 'save_clan_season_results');

      const response = await fetch('https://ninjarift.org/adminpanel/dashboard.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        setStatusMessage(data.message);
        fetchServerStatus();
      } else {
        setStatusMessage(data.error || 'Error saving season results');
      }
    } catch (err) {
      console.error('Error saving season results:', err);
      setStatusMessage('Error saving season results');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEndCrewSeason = async () => {
    const sessionData = localStorage.getItem('strategicSession');
    if (!sessionData) return;

    setIsLoading(true);
    setStatusMessage('');

    try {
      const { acc_id, web_session_key } = JSON.parse(sessionData);
      const formData = new URLSearchParams();
      formData.append('acc_id', acc_id);
      formData.append('web_session_key', web_session_key);
      formData.append('action', 'end_crew_season');

      const response = await fetch('https://ninjarift.org/adminpanel/dashboard.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        setStatusMessage(data.message);
        fetchServerStatus();
      } else {
        setStatusMessage(data.error || 'Error ending crew season');
      }
    } catch (err) {
      console.error('Error ending crew season:', err);
      setStatusMessage('Error ending crew season');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveCrewResults = async () => {
    const sessionData = localStorage.getItem('strategicSession');
    if (!sessionData) return;

    setIsLoading(true);
    setStatusMessage('');

    try {
      const { acc_id, web_session_key } = JSON.parse(sessionData);
      const formData = new URLSearchParams();
      formData.append('acc_id', acc_id);
      formData.append('web_session_key', web_session_key);
      formData.append('action', 'save_crew_season_results');

      const response = await fetch('https://ninjarift.org/adminpanel/dashboard.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        setStatusMessage(data.message);
        fetchServerStatus();
      } else {
        setStatusMessage(data.error || 'Error saving crew season results');
      }
    } catch (err) {
      console.error('Error saving crew season results:', err);
      setStatusMessage('Error saving crew season results');
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartNewCrewSeason = async () => {
    const sessionData = localStorage.getItem('strategicSession');
    if (!sessionData) return;

    setIsLoading(true);
    setStatusMessage('');

    try {
      const { acc_id, web_session_key } = JSON.parse(sessionData);
      const formData = new URLSearchParams();
      formData.append('acc_id', acc_id);
      formData.append('web_session_key', web_session_key);
      formData.append('action', 'start_new_crew_season');

      const response = await fetch('https://ninjarift.org/adminpanel/dashboard.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        setStatusMessage(data.message);
        fetchServerStatus();
      } else {
        setStatusMessage(data.error || 'Error starting new crew season');
      }
    } catch (err) {
      console.error('Error starting new crew season:', err);
      setStatusMessage('Error starting new crew season');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="server-settings-panel">
      <div className="data-item">
        <label>Server Time</label>
        <div className="server-time">
          {serverTime}
        </div>
      </div>

      <div className="data-item">
        <label>Maintenance Mode</label>
        <div className="maintenance-status">
          <div className={`status-indicator ${maintenanceMode ? 'enabled' : 'disabled'}`}>
            {maintenanceMode ? 'Under Maintenance' : 'Live'}
          </div>
          <div className="toggle-container">
            <button 
              className={`toggle-btn ${maintenanceMode ? 'active' : ''}`}
              onClick={handleMaintenanceToggle}
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : maintenanceMode ? 'Disable' : 'Enable'}
            </button>
          </div>
        </div>
      </div>

      <div className="data-item">
        <label>Schedule Maintenance</label>
        <div className="schedule-controls">
          <form onSubmit={handleScheduleMaintenance}>
            <div className="timezone-note">
              Enter times in server time (America/Chicago)
            </div>
            <div className="schedule-inputs">
              <div className="input-group">
                <label>Start Time (Chicago)</label>
                <input
                  type="datetime-local"
                  value={scheduleForm.startTime}
                  onChange={(e) => setScheduleForm(prev => ({
                    ...prev,
                    startTime: e.target.value,
                    endTime: prev.endTime && e.target.value > prev.endTime ? e.target.value : prev.endTime
                  }))}
                  min={getCurrentChicagoTime()}
                  required
                />
              </div>
              <div className="input-group">
                <label>End Time (Chicago)</label>
                <input
                  type="datetime-local"
                  value={scheduleForm.endTime}
                  onChange={(e) => setScheduleForm(prev => ({ 
                    ...prev, 
                    endTime: e.target.value 
                  }))}
                  min={scheduleForm.startTime || getCurrentChicagoTime()}
                  required
                />
              </div>
            </div>
            {scheduleForm.startTime && scheduleForm.endTime && (
              <div className="time-preview">
                Duration: {getDuration(scheduleForm.startTime, scheduleForm.endTime)}
              </div>
            )}
            <button 
              type="submit" 
              className="schedule-btn"
              disabled={isLoading}
            >
              Schedule Maintenance
            </button>
          </form>
        </div>
      </div>

      {scheduledMaintenance && (
        <div className="data-item">
          <label>Scheduled Maintenance</label>
          <div className="scheduled-info">
            <div className="schedule-details">
              <p>
                <span>Start Time</span>
                <strong>{formatDateTime(scheduledMaintenance.start_time)}</strong>
              </p>
              <p>
                <span>End Time</span>
                <strong>{formatDateTime(scheduledMaintenance.end_time)}</strong>
              </p>
              <p>
                <span>Duration</span>
                <strong>{getDuration(scheduledMaintenance.start_time, scheduledMaintenance.end_time)}</strong>
              </p>
              <p>
                <span>Status</span>
                <span className={`status ${scheduledMaintenance.status}`}>
                  {scheduledMaintenance.status === 'scheduled' ? (
                    getTimeFromNow(scheduledMaintenance.start_time)
                  ) : (
                    scheduledMaintenance.status.replace('_', ' ')
                  )}
                </span>
              </p>
            </div>
            {scheduledMaintenance.status === 'scheduled' && (
              <button 
                className="cancel-btn"
                onClick={handleCancelSchedule}
                disabled={isLoading}
              >
                Cancel Scheduled Maintenance
              </button>
            )}
          </div>
        </div>
      )}

      <div className="data-item">
        <label>Clan Season Management</label>
        <div className="clan-season-status">
          <div className="season-info">
            <p>
              <span>Current Season</span>
              <strong>{clanSeasonInfo.currentSeason}</strong>
            </p>
            <p>
              <span>Status</span>
              <span className={`status ${clanSeasonInfo.isActive ? 'active' : 'ended'}`}>
                {clanSeasonInfo.isActive ? 'Active' : 'Season Ended'}
              </span>
            </p>
            {!clanSeasonInfo.isActive && (
              <p>
                <span>Last Season End</span>
                <strong>{formatDateTime(clanSeasonInfo.endDate)}</strong>
              </p>
            )}
            {!clanSeasonInfo.isActive && (
              <p>
                <span>Results Status</span>
                <span className={`status ${clanSeasonInfo.resultsSaved ? 'saved' : 'not-saved'}`}>
                  {clanSeasonInfo.resultsSaved ? 'Results Saved' : 'Results Not Saved'}
                </span>
              </p>
            )}
            {clanSeasonInfo.isActive && clanSeasonInfo.endDate && (
              <p>
                <span>Season Ends</span>
                <strong>{formatDateTime(clanSeasonInfo.endDate)}</strong>
              </p>
            )}
          </div>
          <div className="season-controls">
            {clanSeasonInfo.isActive ? (
              <button
                className="end-season-btn"
                onClick={handleEndClanSeason}
                disabled={isLoading}
              >
                End Current Season
              </button>
            ) : (
              <>
                {!clanSeasonInfo.resultsSaved && (
                  <button
                    className="save-results-btn"
                    onClick={handleSaveResults}
                    disabled={isLoading}
                  >
                    Save Season Results
                  </button>
                )}
                <button
                  className="start-season-btn"
                  onClick={handleStartNewClanSeason}
                  disabled={isLoading || !clanSeasonInfo.resultsSaved}
                >
                  Start New Season
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="data-item">
        <label>Crew Season Management</label>
        <div className="crew-season-status">
          <div className="season-info">
            <p>
              <span>Current Season</span>
              <strong>{crewSeasonInfo.currentSeason}</strong>
            </p>
            <p>
              <span>Status</span>
              <span className={`status ${crewSeasonInfo.isActive ? 'active' : 'ended'}`}>
                {crewSeasonInfo.isActive ? 'Active' : 'Season Ended'}
              </span>
            </p>
            {crewSeasonInfo.isActive && (
              <p>
                <span>Current Phase</span>
                <span className={`status phase-${crewSeasonInfo.phase}`}>
                  Phase {crewSeasonInfo.phase}
                </span>
              </p>
            )}
            {!crewSeasonInfo.isActive && crewSeasonInfo.endDate && (
              <p>
                <span>Last Season End</span>
                <strong>{formatDateTime(crewSeasonInfo.endDate)}</strong>
              </p>
            )}
            {!crewSeasonInfo.isActive && (
              <p>
                <span>Results Status</span>
                <span className={`status ${crewSeasonInfo.resultsSaved ? 'saved' : 'not-saved'}`}>
                  {crewSeasonInfo.resultsSaved ? 'Results Saved' : 'Results Not Saved'}
                </span>
              </p>
            )}
            {crewSeasonInfo.isActive && crewSeasonInfo.endDate && (
              <>
                <p>
                  <span>Season Ends</span>
                  <strong>{formatDateTime(crewSeasonInfo.endDate)}</strong>
                </p>
                {crewSeasonInfo.phase === 2 && crewSeasonInfo.phase2EndDate && (
                  <p>
                    <span>Phase 2 Ends</span>
                    <strong className="phase-end-date">{formatDateTime(crewSeasonInfo.phase2EndDate)}</strong>
                  </p>
                )}
              </>
            )}
          </div>
          <div className="season-controls">
            {crewSeasonInfo.isActive ? (
              <button
                className="end-season-btn"
                onClick={handleEndCrewSeason}
                disabled={isLoading}
              >
                End Current Season
              </button>
            ) : (
              <>
                {!crewSeasonInfo.resultsSaved && (
                  <button
                    className="save-results-btn"
                    onClick={handleSaveCrewResults}
                    disabled={isLoading}
                  >
                    Save Season Results
                  </button>
                )}
                <button
                  className="start-season-btn"
                  onClick={handleStartNewCrewSeason}
                  disabled={isLoading || !crewSeasonInfo.resultsSaved}
                >
                  Start New Season
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {statusMessage && (
        <div className={`status-message ${statusMessage.includes('Error') ? 'error' : 'success'}`}>
          {statusMessage}
        </div>
      )}
    </div>
  );
};

export default ServerSettings;
