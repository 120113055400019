import React, { useState, useEffect } from 'react';
import { 
  LayoutGrid, 
  FileText, 
  Users, 
  Settings, 
  Search,
  LogOut,
  User,
  Clock,
  Info,
  Moon,
  Sun
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import CategoryManager from './CategoryManager';
import ArticleManager from './ArticleManager';
import UserManager from './UserManager';
import WikiSettings from './WikiSettings';
import './AdminPanel.scss';

const AdminPanel = () => {
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState('categories');
  const [searchQuery, setSearchQuery] = useState('');
  const [adminData, setAdminData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // First check localStorage
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      // If theme is saved in localStorage, use that value
      return savedTheme === 'dark';
    }
    // If no saved preference, check system preference
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });

  // Update current time
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    // Apply theme class to html element (root level)
    document.documentElement.classList.toggle('dark', isDarkMode);
    // Save preference to localStorage
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  // Check authentication and fetch admin data
  useEffect(() => {
    const checkAuth = () => {
      const isAuth = localStorage.getItem('isAdminAuthenticated');
      const storedAdminData = localStorage.getItem('adminData');
      const lastLoginTime = localStorage.getItem('lastLoginTime');

      if (!isAuth || !storedAdminData) {
        navigate('/admin/AdminLogin');
        return;
      }

      try {
        const parsedData = JSON.parse(storedAdminData);
        if (!lastLoginTime) {
          localStorage.setItem('lastLoginTime', new Date().toISOString());
        }
        setAdminData({
          ...parsedData,
          last_login: lastLoginTime || new Date().toISOString()
        });
      } catch (err) {
        console.error('Error parsing admin data:', err);
        navigate('/admin/AdminLogin');
      }
    };

    checkAuth();
    setIsLoading(false);
  }, [navigate]);

  const toggleTheme = () => {
    setIsDarkMode(prev => !prev);
  };

  const handleLogout = () => {
    localStorage.setItem('lastLoginTime', new Date().toISOString());
    localStorage.removeItem('isAdminAuthenticated');
    localStorage.removeItem('adminData');
    navigate('/admin/AdminLogin');
  };

  const formatTime = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  const formatLastLogin = (dateString) => {
    if (!dateString) return 'Not available';
    try {
      const date = new Date(dateString);
      const now = new Date();
      const diffInMinutes = Math.floor((now - date) / (1000 * 60));
      
      if (diffInMinutes < 1) {
        return 'Just now';
      } else if (diffInMinutes < 60) {
        return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
      } else {
        const diffInHours = Math.floor(diffInMinutes / 60);
        if (diffInHours < 24) {
          return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
        } else {
          return date.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          });
        }
      }
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Not available';
    }
  };

  const navigation = [
    { id: 'categories', name: 'Categories', icon: <LayoutGrid /> },
    { id: 'articles', name: 'Articles', icon: <FileText /> },
    { id: 'users', name: 'Users', icon: <Users /> },
    { id: 'settings', name: 'Settings', icon: <Settings /> },
  ];

  const renderContent = () => {
    switch (activeSection) {
      case 'categories':
        return <CategoryManager 
          searchQuery={searchQuery} 
          action="get" 
        />;
      case 'articles':
        return <ArticleManager searchQuery={searchQuery} />;
      case 'users':
        return <UserManager searchQuery={searchQuery} />;
      case 'settings':
        return <WikiSettings adminData={adminData} />;
      default:
        return null;
    }
  };

  if (isLoading) {
    return (
      <div className="loading-screen">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className={`admin-container ${isDarkMode ? 'dark' : ''}`}>
      {/* Sidebar */}
      <aside className="admin-sidebar">
        <div className="sidebar-header">
          <div className="header-top">
            <h1>Wiki Admin</h1>
            <button className="theme-toggle" onClick={toggleTheme}>
              {isDarkMode ? <Sun className="theme-icon" /> : <Moon className="theme-icon" />}
            </button>
          </div>
          {adminData && (
            <div className="admin-profile">
              <div className="admin-avatar">
                <User className="avatar-icon" />
              </div>
              <div className="admin-info">
                <span className="admin-name">{adminData.full_name}</span>
                <div className="admin-details">
                  <div className="time-info">
                    <Clock className="icon" size={14} />
                    <span>{formatTime(currentTime)}</span>
                  </div>
                  <div className="last-activity" title={adminData.last_login ? new Date(adminData.last_login).toLocaleString() : ''}>
                    <Info className="icon" size={14} />
                    <span>Last login: {formatLastLogin(adminData.last_login)}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <nav className="sidebar-nav">
          <div className="nav-items-group">
            {navigation.map((item) => (
              <button
                key={item.id}
                className={`nav-item ${activeSection === item.id ? 'active' : ''}`}
                onClick={() => setActiveSection(item.id)}
              >
                {item.icon}
                <span>{item.name}</span>
              </button>
            ))}
          </div>
          <button className="logout-button" onClick={handleLogout}>
            <LogOut />
            <span>Logout</span>
          </button>
        </nav>
      </aside>

      {/* Main Content */}
      <main className="admin-main">
        <div className="main-header">
          <div className="header-content">
            <div className="search-bar">
              <Search className="search-icon" />
              <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="main-content">
          {renderContent()}
        </div>
      </main>
    </div>
  );
};

export default AdminPanel;